// import Swiper JS
import Swiper, { Navigation, Pagination, A11y, Keyboard } from "swiper";

const contentSlider = {
  init() {
    this.setupSlider();
  },

  setupSlider() {
    Swiper.use([Navigation, Pagination, A11y, Keyboard]);

    const swiper = new Swiper("#projects-slider", {
      spaceBetween: 112,
      followFinger: false,
      // Navigation arrows
      navigation: {
        nextEl: "#projects-slider-next",
        prevEl: "#projects-slider-prev",
      },
      pagination: {
        el: "#projects-slider-pagination",
        type: "fraction",
      },
    });
  },
};

export default contentSlider;
