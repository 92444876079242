const navigation = {
  init() {
    this.bindNav();
  },

  // Toggle the state of the target element (nav menu in this case)
  // Show the overlay when the menu is open
  // If the target is not found / specified, show a warning
  toggleMenu(elem) {
    const toggleTarget = document.querySelector(elem.dataset.toggleTarget);

    if (toggleTarget == null) {
      console.warn("Can not find target or target not specified");
      return;
    }

    document.body.classList.toggle("overlay-visible");
    toggleTarget.classList.toggle("open");
  },

  // Replace the nav button text between open and close state
  // The data attribute is used to store the alternative state
  // If there is no data attribute nothing happens
  toggleText(elem) {
    const toggleValue = elem.querySelector("[data-toggle-value]");

    if (toggleValue == null) return;

    const toggleValueCurrent = toggleValue.innerText;
    const toggleValueNew = toggleValue.dataset.toggleValue;

    toggleValue.innerText = toggleValueNew;
    toggleValue.dataset.toggleValue = toggleValueCurrent;
  },

  // Replace the nav button icon between open and close state
  // The data attribute is used to store the alternative state
  // If there is no data attribute nothing happens
  toggleIcon(elem) {
    const toggleSrc = elem.querySelector("[data-toggle-src]");

    if (toggleSrc == null) return;

    const toggleSrcCurrent = toggleSrc.src;
    const toggleSrcNew = toggleSrc.dataset.toggleSrc;

    toggleSrc.src = toggleSrcNew;
    toggleSrc.dataset.toggleSrc = toggleSrcCurrent;
  },

  // Toggle open and close state for the aria attributes
  // If there is not all aria attribute are present a warning is shown
  toggleAria(elem) {
    const toggleTarget = document.querySelector(elem.dataset.toggleTarget);

    if (
      toggleTarget.getAttribute("aria-hidden") === null ||
      elem.getAttribute("aria-expanded") === null
    ) {
      console.warn("Aria labels not set correct");
      return;
    }

    const expandedState =
      elem.getAttribute("aria-expanded") === "true" ? "false" : "true";
    const hiddenState =
      toggleTarget.getAttribute("aria-hidden") === "true" ? "false" : "true";

    elem.setAttribute("aria-expanded", expandedState);
    toggleTarget.setAttribute("aria-hidden", hiddenState);
  },

  bindNav() {
    // do nav
    const toggle = document.querySelector("[data-toggle='navigation']");

    toggle.addEventListener("click", (e) => {
      const elem = e.currentTarget;

      // Toggle menu
      this.toggleMenu(elem);

      // Toggle button text
      this.toggleText(elem);

      // Toggle button icon
      this.toggleIcon(elem);

      // Toggle menu
      this.toggleAria(elem);
    });

    const menu = document.querySelector("#primary-navigation");
    const menuItems = menu.querySelectorAll('a');

    menuItems.forEach(item => {
      item.addEventListener("click", (e) => {
        toggle.click();
      });
    });
  },
};

export default navigation;
