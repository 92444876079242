import { domReady } from "@roots/sage/client";

// Modules
import load from "./modules/load";
import navigation from "./modules/navigation";
import imageGalleries from "./modules/imageGalleries";
import contentSlider from "./modules/contentSlider";
import reviewSlider from "./modules/reviewSlider";

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
  load.init();
  navigation.init();
  imageGalleries.init();
  contentSlider.init();
  reviewSlider.init();
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
