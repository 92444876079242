const load = {
  init() {
    this.onFirstLoad();
  },

  // Hide elements on first load to prevent first time animation to run
  onFirstLoad() {
    setTimeout(function () {
      document.getElementById("app").classList.remove("loading");
    }, 500);
  },
};

export default load;
