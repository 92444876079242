// import Swiper JS
import Swiper, { Navigation, A11y, Keyboard } from "swiper";

const reviewSlider = {
  init() {
    this.setupSlider();
  },

  setupSlider() {
    Swiper.use([Navigation, A11y, Keyboard]);

    const swiper = new Swiper("#review-slider", {
      spaceBetween: 20,
      slidesPerView: 1,
      breakpoints: {
        900: {
          slidesPerView: 3,
        },
        570: {
          slidesPerView: 2,
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: "#review-slider-next",
        prevEl: "#review-slider-prev",
      },
      on: {
        init() {
          console.log("swiper initialized");
        },

        slideChange() {
          console.log("swiper initialized");
        },
      },
    });
  },
};

export default reviewSlider;
