import { Fancybox } from "@fancyapps/ui";

const imageGalleries = {
  init() {
    this.bindFigCaption();
    this.setupGalleries();
  },

  bindFigCaption() {
    // Get all Gutenberg image blocks
    const gutenbergImages = document.querySelectorAll('.wp-block-image');

    gutenbergImages.forEach(block => {
      var caption = block.querySelector('figcaption');

      // Use figcaption element if available, bind to img tag.
      if (caption) {
        var imageTag = block.querySelector('img');

        imageTag.setAttribute('data-caption', caption.innerText);
      }
    });
  },

  setupGalleries() {
    // Collect all potential galleries.
    const galleries = document.querySelectorAll(
      ".wp-block-gallery, .project__gallery"
    );

    // Foreach gallery block, bind Fancybox to each image inside that block.
    galleries.forEach((gallery) => {
      // Target only the images inside current element, prevent binding images outside of gallery
      Fancybox.bind("." + gallery.classList[0] + " img", {
        groupAll: true,
      });
    });
  },
};

export default imageGalleries;
